import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';
import { RolesService } from './roles.service';

export const authGuard = (): boolean => {
  const router: Router = inject(Router);
  const authService: AuthService = inject(AuthService);
  if (authService.isLoggedIn()) {
    return true;
  } else {
    router.navigate(['/page-not-found']);
    return false;
  }
};

export const adminGuard = (): boolean => {
  const rolesService: RolesService = inject(RolesService);
  const router: Router = inject(Router);
  const isAdmin: boolean = rolesService.isAdmin();
  if (isAdmin) {
    return true;
  } else {
    router.navigate(['/page-not-found']);
    return false;
  }
};
export const userGuard = (): boolean => {
  const rolesService: RolesService = inject(RolesService);
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);
  const isUser: boolean = rolesService.isUser();
  const isPasswordChanged: string | null = localStorage.getItem('isChanged');
  if (isUser && isPasswordChanged === 'true') {
    return true;
  } else if (isUser && isPasswordChanged !== 'true') {
    router.navigate(['/signin']);
    authService.logout();
    return false;
  } else {
    router.navigate(['/page-not-found']);
    authService.logout();
    return false;
  }
};
