<div
  class="standard-ad-card"
  (click)="navigateToCampaignLandingPage(ad.campaignUrlId)">
  <div class="standard-ad-card-holder">
    <h3 class="standard-ad-card-company">
      {{ ad.companyName }}
    </h3>
    <p class="standard-ad-card-campaign-title">{{ ad.campaignTitle }}</p>
    <div class="ad-card-location-holder">
      <span class="material-symbols-outlined"> location_on </span>&nbsp;{{
        ad.jobLocation
      }}
    </div>
  </div>
</div>
