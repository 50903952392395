import { Injectable, NgZone } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  first,
  Observable,
  tap,
  throwError,
} from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthModel } from './models/auth.model';
import { LoginResponseModel } from './models/login-response.model';
import { PasswordChangeModel } from './models/password-change.model';
import { SocialUser } from '@abacritt/angularx-social-login';
import { AccessTokenModel } from './models/access-token.model';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly ACCESS_TOKEN = 'token';
  private readonly CURRENT_USER_ROLE = 'role';
  private readonly CURRENT_USER_ID = 'userId';
  private readonly CURRENT_COMPANY_NAME = 'companyName';
  private readonly CURRENT_USER_PASSWORD_CHANGED = 'isChanged';
  private readonly CANDIDATE_NAME = 'fullName';
  private apiServerUrl: string = environment.apiBaseUrl;
  public isLogged$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private router: Router,
    private ngZone: NgZone
  ) {}

  public login(loginData: AuthModel): void {
    localStorage.setItem('initial', loginData.email.charAt(0).toUpperCase());
    localStorage.setItem('userEmail', loginData.email);
    this.userAuthenticate(loginData)
      .pipe(
        catchError(this.handleError),
        first(),
        tap((authResponse: LoginResponseModel): void => {
          this.handleCredentials(authResponse);
          this.isLogged$.next(true);
          if (authResponse.role === 'USER') {
            if (authResponse.firstLogin) {
              this.ngZone.run(() => {
                this.router.navigate(['password-change']);
              });
            } else {
              this.ngZone.run(() => {
                this.router.navigate(['dashboard']);
              });
            }
          } else if (authResponse.role === 'ADMIN') {
            this.ngZone.run(() => {
              this.router.navigate(['admin/companies']);
            });
          }
        })
      )
      .subscribe();
  }

  private userAuthenticate(
    loginData: AuthModel
  ): Observable<LoginResponseModel> {
    const requestOptions = {
      headers: new HttpHeaders(),
      withCredentials: true,
    };

    return this.http.post<LoginResponseModel>(
      `${this.apiServerUrl}/auth/authenticate`,
      loginData,
      requestOptions
    );
  }
  private handleCredentials(response: LoginResponseModel): void {
    localStorage.setItem(this.CURRENT_USER_ROLE, response.role);
    localStorage.setItem(this.ACCESS_TOKEN, response.token);
    localStorage.setItem(this.CURRENT_USER_ID, response.userId + '');
    localStorage.setItem(this.CURRENT_COMPANY_NAME, response.companyName);
    localStorage.setItem(
      this.CURRENT_USER_PASSWORD_CHANGED,
      !response.firstLogin + ''
    );
    localStorage.setItem(this.CANDIDATE_NAME, response.fullName);
    sessionStorage.setItem('authenticated', 'true');
  }
  public isLoggedIn(): boolean {
    return !!localStorage.getItem(this.ACCESS_TOKEN);
  }
  public getAccessToken(): string | null {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }
  public logout() {
    this.removeCredentials();
    this.isLogged$.next(false);
    window.open('/signin', '_self');
  }
  private removeCredentials(): void {
    localStorage.clear();
    sessionStorage.clear();
  }
  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = '';
    if (error.status === 403) {
      errorMessage = 'Wrong email or password';
    } else {
      errorMessage = `Error: ${error.error.message}`;
    }
    alert(errorMessage);
    return throwError(() => {
      return errorMessage;
    });
  }

  private storeAccessToken(accessToken: string): void {
    localStorage.setItem(this.ACCESS_TOKEN, accessToken);
  }

  public refreshToken(): Observable<AccessTokenModel> {
    const requestOptions = {
      headers: new HttpHeaders(),
      withCredentials: true,
    };
    return this.http
      .post<AccessTokenModel>(
        `${this.apiServerUrl}/auth/refresh-token`,
        {},
        requestOptions
      )
      .pipe(
        tap((accessToken: AccessTokenModel): void => {
          this.storeAccessToken(accessToken.token);
        })
      );
  }

  public changePassword(newPassword: PasswordChangeModel): void {
    this.http
      .post<PasswordChangeModel>(
        `${this.apiServerUrl}/auth/change-password`,
        newPassword
      )
      .pipe(
        catchError(this.handleError),
        first(),
        tap((authResponse: PasswordChangeModel): void => {
          if (!authResponse.firstLogin) {
            localStorage.setItem(
              this.CURRENT_USER_PASSWORD_CHANGED,
              !authResponse.firstLogin + ''
            );
            this.ngZone.run(() => {
              this.router.navigate(['dashboard']);
            });
          } else {
            this.logout();
          }
        })
      )
      .subscribe();
  }

  public loginSocial(user: SocialUser): Promise<void> {
    if (user.provider == 'FACEBOOK') {
      return this.userFacebookAuthenticate(user.authToken);
    } else {
      return this.userGoogleAuthenticate(user.idToken);
    }
  }

  private userGoogleAuthenticate(token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${token}`,
        }),
        withCredentials: true,
      };
      this.http
        .post<LoginResponseModel>(
          `${this.apiServerUrl}/auth/authenticate/google`,
          {},
          requestOptions
        )
        .pipe(
          catchError(error => {
            this.handleError(error);
            return throwError(() => new Error(error));
          }),
          first(),
          tap((authResponse: LoginResponseModel): void => {
            this.handleCredentials(authResponse);
            resolve();
          })
        )
        .subscribe();
    });
  }

  private userFacebookAuthenticate(token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        headers: new HttpHeaders({
          Authorization: `Bearer ${token}`,
        }),
        withCredentials: true,
      };
      this.http
        .post<LoginResponseModel>(
          `${this.apiServerUrl}/auth/authenticate/facebook`,
          {},
          requestOptions
        )
        .pipe(
          catchError(error => {
            this.handleError(error);
            return throwError(() => new Error(error));
          }),
          first(),
          tap((authResponse: LoginResponseModel): void => {
            this.handleCredentials(authResponse);
            resolve();
          })
        )
        .subscribe();
    });
  }
}
