import { Injectable } from '@angular/core';

import { RoleTypes } from './enums/role-types';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  private readonly CURRENT_USER_ROLE = 'role';

  public getRole(): string | null {
    const currentUserRole: string | null = localStorage.getItem(
      this.CURRENT_USER_ROLE
    );
    if (currentUserRole === null) {
      return null;
    }
    const role: string = currentUserRole;
    if (role === null) {
      return null;
    }
    return role;
  }
  public isAdmin(): boolean {
    return this.getRole() === RoleTypes.ADMIN;
  }
  public isUser(): boolean {
    return this.getRole() === RoleTypes.USER;
  }
}
