import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'talentmarketDatePipe',
})
export class TalentmarketDatePipe implements PipeTransform {
  public transform(value: string | Date): string {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    return new Date(value).toLocaleString('hr-HR', options);
  }
}
