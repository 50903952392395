import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AdModel } from '../../models/ad.model';

@Component({
  selector: 'app-elite-ad-card',
  templateUrl: './elite-ad-card.component.html',
  styleUrls: ['./elite-ad-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EliteAdCardComponent {
  @Input() ad!: AdModel;

  constructor(private router: Router) {}
  public navigateToCampaignLandingPage(campaignUrlId: string): void {
    const url: string = this.router.serializeUrl(
      this.router.createUrlTree([`clp/${campaignUrlId}`])
    );
    window.open(url, '_blank');
  }
  public setRGBColor(companyHexColor: string): string {
    const companyRGBColor: string | null = this.hexToRgb(companyHexColor);
    if (companyRGBColor) {
      return companyRGBColor;
    } else {
      return 'rgba(255, 255, 255, 1)';
    }
  }
  private hexToRgb(hex: string): string | null {
    const sanitizedHex: string = hex.replace(/^#/, '');
    if (!/^([0-9A-F]{3}|[0-9A-F]{6})$/i.test(sanitizedHex)) {
      return null;
    }
    const fullHex: string =
      sanitizedHex.length === 3
        ? sanitizedHex
            .split('')
            .map((char: string) => char + char)
            .join('')
        : sanitizedHex;
    const r: number = parseInt(fullHex.substring(0, 2), 16);
    const g: number = parseInt(fullHex.substring(2, 4), 16);
    const b: number = parseInt(fullHex.substring(4, 6), 16);
    return `rgb(${r}, ${g}, ${b}, 0.3)`;
  }
}
