import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject, takeUntil, tap } from 'rxjs';

import { LandingPage } from '../../models/landing-page.model';
import { AdminService } from '../../../admin/services/admin.service';
import { CampaignService } from '../../../admin/services/campaign.service';
import { faPlay, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-campaign-landing-page',
  templateUrl: './campaign-landing-page.component.html',
  styleUrls: ['./campaign-landing-page.component.scss'],
})
// TODO refactor ts
export class CampaignLandingPageComponent implements OnInit, OnDestroy {
  public faPlay: IconDefinition = faPlay;

  public selectedCampaignId: number | null = null;
  public selectedCompanyId: number | null = null;
  public selectedCampaignUrlId: string | null = null;
  public landingPage: LandingPage = {
    header1: '',
    header2: '',
    header3: '',
    callToAction1: '',
    aboutUs1: '',
    goal: '',
    callToAction2: '',
    offer: '',
    callToAction3: '',
    catButton1: '',
    catButton2: '',
    gdprNotice: '',
    imageId: '',
    imageId2: '',
    browserTitle: '',
    customUrl: '',
  };
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  imageId1!: string;
  imageId2!: string;

  constructor(
    private adminService: AdminService,
    private campaignService: CampaignService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private router: Router,
    private customTitle: Title
  ) {}

  ngOnInit(): void {
    this.selectedCompanyId = +this.router.url.split('/')[2];
    this.selectedCampaignId = +this.router.url.split('/')[5];
    this.route.paramMap.subscribe((params: ParamMap): void => {
      this.selectedCampaignUrlId = params.get('urlId');
    });
    if (this.selectedCampaignUrlId) {
      this.getCampaignLandingPage(this.selectedCampaignUrlId);
    } else {
      this.getCampaignLandingPagePreview(this.selectedCampaignId);
    }
    if (this.selectedCompanyId) {
      this.adminService.getCompanyDetails(this.selectedCompanyId);
    }
  }
  public getCampaignLandingPage(campaignUrlId: string): void {
    this.campaignService
      .getCampaignLandingPage(campaignUrlId)
      .pipe(
        takeUntil(this.destroyed$),
        tap((responseData: LandingPage): void => {
          if (responseData !== null) {
            this.imageId1 = responseData.imageId;
            this.imageId2 = responseData.imageId2;
            this.landingPage = responseData;
            this.customTitle.setTitle(this.landingPage.browserTitle);
          }
        })
      )
      .subscribe();
  }
  public getCampaignLandingPagePreview(campaignId: number): void {
    this.campaignService
      .getCampaignLandingPagePreview(campaignId)
      .pipe(
        takeUntil(this.destroyed$),
        tap((responseData: LandingPage): void => {
          if (responseData !== null) {
            this.imageId1 = responseData.imageId;
            this.imageId2 = responseData.imageId2;
            this.landingPage = responseData;
            this.customTitle.setTitle(this.landingPage.browserTitle);
          }
        })
      )
      .subscribe();
  }
  public getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  public goToCampaignQuiz(): void {
    if (this.selectedCompanyId) {
      this.router
        .navigate([
          `admin/${this.selectedCompanyId}/create-campaign/quiz-preview/${this.selectedCampaignId}`,
        ])
        .then();
    } else {
      this.router.navigate([`clp/${this.selectedCampaignUrlId}`]).then();
      const url = '/q/' + this.selectedCampaignUrlId;
      this.router.navigateByUrl(url).then();
    }
  }
  public navigateToCampaignBuilder(): void {
    if (this.selectedCompanyId) {
      this.adminService.getCompanyDetails(this.selectedCompanyId);
      this.router
        .navigate([
          `admin/${this.selectedCompanyId}/create-campaign/quiz/${this.selectedCampaignId}`,
        ])
        .then();
    }
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
