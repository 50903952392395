import { Component } from '@angular/core';

import { AuthService } from '../../login/auth.service';

@Component({
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  public displayBackButton = true;

  constructor(private authService: AuthService) {
    this.displayBackButton = !this.authService.isLoggedIn();
  }

  public backToLogin(): void {
    this.authService.logout();
  }
}
