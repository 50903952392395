<button
  *ngIf="selectedCompanyId"
  (click)="navigateToCampaignBuilder()"
  class="m-2">
  Back to Campaign builder
</button>
<div class="campaign-hero-section-holder">
  <div class="campaign-hero-holder">
    <div class="campaign-hero-content">
      <h1
        class="campaign-title"
        [innerHTML]="getSafeHtml(landingPage.header1)"></h1>
      <button class="cat-button" (click)="goToCampaignQuiz()">
        <fa-icon [icon]="faPlay"></fa-icon>&nbsp;
        <span
          class="cat-button-text"
          [innerHTML]="getSafeHtml(landingPage.catButton1)"></span>
      </button>
      <span
        class="campaign-caption"
        [innerHTML]="getSafeHtml(landingPage.callToAction1)">
      </span>
    </div>
    <div class="campaign-image-holder">
      <img
        class="campaign-image"
        src="https://storage.googleapis.com/publicpicstm/{{ imageId1 }}"
        alt="Campaign image" />
    </div>
  </div>
</div>
<div class="campaign-about-us-section-holder">
  <div class="campaign-about-us-holder">
    <h2
      class="campaign-subtitle"
      [innerHTML]="getSafeHtml(landingPage.header2)"></h2>
    <p class="campaign-about-us-text">
      <span [innerHTML]="getSafeHtml(landingPage.aboutUs1)"></span>
      <br />
      <br />
      <span [innerHTML]="getSafeHtml(landingPage.goal)"></span>
      <br />
      <br />
      <span [innerHTML]="getSafeHtml(landingPage.callToAction2)"></span>
    </p>
  </div>
</div>
<div class="campaign-our-offer-section-holder">
  <div class="campaign-our-offer-holder">
    <h2
      class="campaign-subtitle"
      [innerHTML]="getSafeHtml(landingPage.header3)"></h2>
    <span
      class="home-text ulOffer"
      [innerHTML]="getSafeHtml(landingPage.offer)">
    </span>
  </div>
  <div class="separator-line"></div>
</div>
<div class="campaign-call-to-action-holder">
  <span
    class="campaign-caption"
    [innerHTML]="getSafeHtml(landingPage.callToAction3)">
  </span>
  <button class="cat-button" (click)="goToCampaignQuiz()">
    <fa-icon [icon]="faPlay"></fa-icon>&nbsp;
    <span
      class="cat-button-text"
      [innerHTML]="getSafeHtml(landingPage.catButton2)"></span>
  </button>
</div>
<div class="campaign-footer">
  <p
    class="campaign-footer-text"
    [innerHTML]="getSafeHtml(landingPage.gdprNotice)"></p>
</div>
