import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { tap, switchMap, scan } from 'rxjs/operators';

import { AdsService } from '../../services/ads.service';
import { FeaturedAdsResponseModel } from '../../models/featured-ads-response.model';

@Component({
  selector: 'app-featured-ads-section',
  templateUrl: './featured-ads-section.component.html',
  styleUrls: ['./featured-ads-section.component.scss'],
})
export class FeaturedAdsSectionComponent implements OnInit, OnDestroy {
  public featuredAds: FeaturedAdsResponseModel = {
    elite: [],
    premium: [],
    standard: [],
    page: 0,
    lastPage: false,
  };
  private loading$ = new BehaviorSubject<boolean>(true);
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private adsService: AdsService) {}

  ngOnInit(): void {
    this.adsService.page$
      .pipe(
        tap(() => this.loading$.next(true)),
        switchMap(page => {
          this.adsService.adsReq.page = page;
          this.adsService.getAllAds();
          return this.adsService.ads$;
        }),
        scan(this.updatePaginator, this.featuredAds),
        tap(updatedAds => {
          this.featuredAds = updatedAds;
          this.loading$.next(false);
        })
      )
      .subscribe();
  }

  private updatePaginator(
    accumulator: FeaturedAdsResponseModel,
    value: FeaturedAdsResponseModel
  ): FeaturedAdsResponseModel {
    if (value.page === 0) {
      return value;
    }
    accumulator.elite.push(...value.elite);
    accumulator.premium.push(...value.premium);
    accumulator.standard.push(...value.standard);
    accumulator.page = value.page;
    accumulator.lastPage = value.lastPage;
    return accumulator;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
