<section class="container contact-us-section-container max-container-width">
  <div class="contact-us-content-holder" *ngIf="sendMailFormGroup">
    <form
      [formGroup]="sendMailFormGroup"
      (ngSubmit)="onSendMail()"
      class="send-mail-form">
      <div class="send-mail-input-holder">
        <div class="send-input-holder">
          <label for="whatPosition">Koju poziciju/e želiš popuniti?</label>
          <input
            placeholder="Unesi poziciju/pozicije"
            type="text"
            formControlName="whatPosition"
            id="whatPosition" />
        </div>
        <div class="send-input-holder">
          <label for="whatLocation">Na kojoj lokaciji/lokacijama?</label>
          <input
            placeholder="Unesi lokaciju/lokacije"
            type="text"
            formControlName="whatLocation"
            id="whatLocation" />
        </div>
        <div class="send-input-holder">
          <label for="numberOfWorkers"
            >Koliko radnika tražiš po poziciji?</label
          >
          <input
            placeholder="Unesi broj radnika po poziciji"
            type="number"
            formControlName="numberOfWorkers"
            id="numberOfWorkers" />
        </div>
        <div class="send-input-holder">
          <label for="message">Poruka:</label>
          <textarea
            id="message"
            formControlName="message"
            class="contact-modal-textarea"
            rows="5"
            cols="30"></textarea>
          <div
            *ngIf="formSubmitted && sendMailFormGroup?.get('message')?.invalid"
            class="text-danger">
            <small
              *ngIf="sendMailFormGroup.get('message')?.errors?.['required']"
              >Poruka je potrebna</small
            >
          </div>
        </div>
        <div class="send-mail-input">
          <label for="firstName">Podaci za kontakt:</label>
          <input
            type="text"
            formControlName="firstName"
            id="firstName"
            placeholder="Ime*" />
          <div
            *ngIf="
              formSubmitted && sendMailFormGroup?.get('firstName')?.invalid
            "
            class="text-danger">
            <small
              *ngIf="sendMailFormGroup.get('firstName')?.errors?.['required']"
              >Ime je potrebno</small
            >
          </div>
          <input
            type="text"
            formControlName="lastName"
            id="lastName"
            placeholder="Prezime*" />
          <div
            *ngIf="formSubmitted && sendMailFormGroup?.get('lastName')?.invalid"
            class="text-danger">
            <small
              *ngIf="sendMailFormGroup.get('lastName')?.errors?.['required']"
              >Prezime je potrebno</small
            >
          </div>
          <input
            type="text"
            formControlName="contactNumber"
            id="contactNumber"
            placeholder="Broj mobitela*" />
          <div
            *ngIf="
              formSubmitted && sendMailFormGroup?.get('contactNumber')?.invalid
            "
            class="text-danger">
            <small
              *ngIf="
                sendMailFormGroup.get('contactNumber')?.errors?.['required']
              "
              >Kontakt broj je potreban</small
            >
          </div>
          <input
            type="text"
            formControlName="companyName"
            id="companyName"
            placeholder="Ime poduzeća*" />
          <div
            *ngIf="
              formSubmitted && sendMailFormGroup?.get('companyName')?.invalid
            "
            class="text-danger">
            <small
              *ngIf="sendMailFormGroup.get('companyName')?.errors?.['required']"
              >Ime kompanije je potrebno</small
            >
          </div>
          <input
            type="email"
            formControlName="contactMail"
            id="contactMail"
            placeholder="Email*" />
          <div
            *ngIf="
              formSubmitted && sendMailFormGroup?.get('contactMail')?.invalid
            "
            class="text-danger">
            <small
              *ngIf="sendMailFormGroup.get('contactMail')?.errors?.['required']"
              >Email je potreban</small
            >
            <small
              *ngIf="sendMailFormGroup.get('contactMail')?.errors?.['email']"
              >Nepravilan email</small
            >
          </div>
        </div>
      </div>
      <button
        class="basic-black-button contact-us-button"
        type="submit"
        aria-label="Send inquiry">
        <fa-icon [icon]="faPaperPlane"></fa-icon>&nbsp; Pošalji upit
      </button>
    </form>
  </div>
</section>
