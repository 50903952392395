import { Component } from '@angular/core';
import { FeaturedAdsResponseModel } from '../models/featured-ads-response.model';
import { AdsService } from '../services/ads.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  public featuredAds: FeaturedAdsResponseModel = {
    elite: [],
    premium: [],
    standard: [],
    page: 0,
    lastPage: false,
  };
  constructor(private adsService: AdsService) {}
  public onScroll(): void {
    if (!this.featuredAds.lastPage) {
      this.adsService.page$.next(this.adsService.page$.value + 1);
    }
  }
}
