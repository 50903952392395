import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserIdleService } from 'angular-user-idle';

import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public loginFormGroup: FormGroup | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private userIdleService: UserIdleService
  ) {
    this.loginFormGroup = this.formBuilder.group({
      email: [null, Validators.required],
      password: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.userIdleService.startWatching();
    this.userIdleService.onTimerStart().subscribe();
    this.userIdleService.onTimeout().subscribe((): void => {
      if (this.authService.isLoggedIn()) {
        this.authService.logout();
      }
    });
  }

  public signIn(): void {
    if (this.loginFormGroup?.valid) {
      this.authService.login({
        email: this.loginFormGroup.value.email,
        password: this.loginFormGroup.value.password,
      });
    }
  }
}
