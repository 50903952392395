<mat-toolbar>
  <div class="left-side">
    <h3>{{ leftSideText }}</h3>
  </div>

  <div class="modal-content">
    <ng-content></ng-content>
  </div>

  <div class="right-side">
    <button
      matTooltip="Close modal"
      mat-icon-button
      color="primary"
      (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-toolbar>
