<button
  *ngIf="selectedCompanyId"
  (click)="navigateToCampaignBuilder()"
  class="m-2">
  Back to Campaign builder
</button>
<div class="thank-you-header-container p-0">
  <nav
    class="header-nav container max-container-width navbar navbar-expand-lg navbar-light w-100">
    <div
      class="header-inner d-flex justify-content-between align-items-center w-100">
      <img
        routerLink="/home"
        class="header-logo"
        src="./assets/talentmarket.logo.webp"
        alt="Talentmarket logo" />
      <div id="navbarNav" class="social-holder">
        <div *ngIf="candidateName">
          <span style="font-size: large; font-weight: bold">
            Hi, {{ candidateName }} !
          </span>
        </div>
        <div *ngIf="!candidateName" class="social-holder">
          <asl-google-signin-button
            type="standard"
            size="large"
            shape="pill"
            text="continue_with"></asl-google-signin-button>
          <button
            class="social-login-button facebook-login"
            (click)="signInWithFB()">
            <i class="fab fa-facebook facebook-icon"></i>
            <span>Continue with Facebook</span>
          </button>
        </div>
      </div>
    </div>
  </nav>
</div>
<div class="container thank-you-page-container">
  <span [innerHTML]="getSafeHtml(thankYou.html)"></span>
</div>
