<mat-toolbar class="warning-modal-container"
  ><h1>Warning</h1>
</mat-toolbar>
<mat-dialog-content>
  {{ message }}
</mat-dialog-content>
<mat-dialog-actions class="warning-button-holder">
  <button
    mat-button
    [mat-dialog-close]="'OK'"
    color="primary"
    class="width-full">
    OK
  </button>
</mat-dialog-actions>
