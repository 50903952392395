import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ContactUsService } from '../services/contact-us.service';
import {
  faPaperPlane,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact-us-page',
  templateUrl: './contact-us-page.component.html',
  styleUrls: ['./contact-us-page.component.scss'],
})
export class ContactUsPageComponent implements OnInit, OnDestroy {
  public faPaperPlane: IconDefinition = faPaperPlane;

  public sendMailFormGroup: FormGroup | null = null;
  public formSubmitted = false;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private contactUsService: ContactUsService
  ) {}
  ngOnInit(): void {
    this.sendMailFormGroup = this.formBuilder.group({
      whatPosition: [''],
      whatLocation: [''],
      numberOfWorkers: [],
      message: ['', Validators.required],
      firstName: [],
      lastName: [],
      contactNumber: ['', Validators.required],
      companyName: ['', Validators.required],
      contactMail: ['', [Validators.required, Validators.email]],
    });
  }
  public onSendMail(): void {
    this.formSubmitted = true;
    if (this.sendMailFormGroup?.valid) {
      this.contactUsService.sendContactMail({
        whatPosition: this.sendMailFormGroup.value.whatPosition,
        whatLocation: this.sendMailFormGroup.value.whatLocation,
        numberOfWorkers: this.sendMailFormGroup.value.numberOfWorkers,
        message: this.sendMailFormGroup.value.message,
        firstName: this.sendMailFormGroup.value.firstName,
        lastName: this.sendMailFormGroup.value.lastName,
        contactNumber: this.sendMailFormGroup.value.contactNumber,
        companyName: this.sendMailFormGroup.value.companyName,
        contactMail: this.sendMailFormGroup.value.contactMail,
      });
      this.sendMailFormGroup.reset();
      this.formSubmitted = false;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
