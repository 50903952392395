import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject, takeUntil, tap } from 'rxjs';

import { ThankYouModel } from '../../models/thank-you.model';
import { CampaignService } from '../../../admin/services/campaign.service';
import { AdminService } from '../../../admin/services/admin.service';
import {
  FacebookLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login'
import { AuthService } from 'src/app/login/auth.service';

@Component({
  selector: 'app-thank-you-page',
  templateUrl: './thank-you-page.component.html',
  styleUrls: ['./thank-you-page.component.scss'],
})
export class ThankYouPageComponent implements OnInit, OnDestroy {
  public selectedCampaignId: number | null = null;
  public candidateName: string | null = localStorage.getItem("fullName");
  public selectedCompanyId: number | null = null;
  public selectedCampaignUrlId: string | null = null;
  public thankYou: ThankYouModel = {
    id: null,
    html: '',
  };

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private campaignService: CampaignService,
    private adminService: AdminService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private socialAuthService: SocialAuthService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.selectedCompanyId = +this.router.url.split('/')[2];
    this.selectedCampaignId = +this.router.url.split('/')[5];
    this.route.paramMap.subscribe((params: ParamMap): void => {
      this.selectedCampaignUrlId = params.get('urlId');
    });
    if (this.selectedCampaignUrlId) {
      this.getThankYou(this.selectedCampaignUrlId);
    } else {
      this.getThankYouPreview(this.selectedCampaignId);
    }
    if (this.selectedCompanyId) {
      this.adminService.getCompanyDetails(this.selectedCompanyId);
    }
    this.socialAuthService.authState.subscribe((user: SocialUser): void => {
      if(user.provider !== 'FACEBOOK') {
        this.authService.loginSocial(user).then(() => {
          this.candidateName = localStorage.getItem("fullName");
        });
      }
    });
  }

  public getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
  public getThankYou(campaignUrlId: string): void {
    this.campaignService
      .getThankYouPage(campaignUrlId)
      .pipe(
        takeUntil(this.destroyed$),
        tap((responseData: ThankYouModel): void => {
          if (responseData !== null) {
            this.thankYou = responseData;
          }
        })
      )
      .subscribe();
  }
  public getThankYouPreview(campaignId: number): void {
    this.campaignService
      .getThankYouPagePreview(campaignId)
      .pipe(
        takeUntil(this.destroyed$),
        tap((responseData: ThankYouModel): void => {
          if (responseData !== null) {
            this.thankYou = responseData;
          }
        })
      )
      .subscribe();
  }
  public navigateToCampaignBuilder(): void {
    if (this.selectedCompanyId) {
      this.adminService.getCompanyDetails(this.selectedCompanyId);
      this.router
        .navigate([
          `admin/${this.selectedCompanyId}/create-campaign/quiz/${this.selectedCampaignId}`,
        ])
        .then();
    }
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public signInWithFB(): void {
    this.socialAuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((user: SocialUser): void => {
        if(user.provider === 'FACEBOOK') {
          this.authService.loginSocial(user).then(() => {
            this.candidateName = localStorage.getItem("fullName");
          });
        }
      })
      .catch(err => {
        console.error(err);
      });
  }
}
