<section class="container hero-section-container max-container-width">
  <div class="hero-section-holder-up">
    <div class="hero-section-content-left">
      <h1 class="hero-title">
        Novi <span class="underline-hero-title">posao</span>. <br />
        Nadohvat ruke. <br />
        Prijava za minutu.
      </h1>
      <div class="search-container">
        <span class="search-icon">
          <img src="assets/search.svg" alt="Search" />
        </span>
        <input
          type="text"
          [formControl]="searchAdsInput"
          placeholder="Gdje tražiš posao? Kakav posao želiš?"
          class="search-input" />
        <button
          class="search-icon-button"
          type="button"
          (click)="navigateToAdsAndSearch()">
          <img src="assets/search.svg" alt="Search" />
        </button>
        <button
          class="search-button"
          type="button"
          (click)="navigateToAdsAndSearch()">
          Pretraži
        </button>
      </div>
    </div>
    <img src="assets/hero-image.png" class="hero-image" alt="Hero image" />
  </div>
</section>
