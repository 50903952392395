<section class="container thank-you-for-contact-us-section-container">
  <div class="thank-you-for-contact-us-content-holder">
    <h1>Hvala na upitu!</h1>
    <p class="m-0">Kontaktirati ćemo vas u najkraćem mogućem roku</p>
    <img
      routerLink="/home"
      class="thank-you-logo"
      src="./assets/talentmarket.logo.webp"
      alt="Talentmarket logo" />
  </div>
</section>
