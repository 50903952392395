import { NgModule } from '@angular/core';

import { MaterialModule } from './material.module';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AppModalHeaderComponent } from './shared/app-modal-header/app-modal-header.component';
import { TalentmarketDatePipe } from './pipes/talentmarket-date.pipe';
import { TalentmarketGradePipe } from './pipes/talentmarket-grade.pipe';
import { WarningModalComponent } from './shared/warning-modal/warning-modal.component';
import { EnumToTextPipe } from './pipes/enum-to-text.pipe';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    AppModalHeaderComponent,
    TalentmarketDatePipe,
    TalentmarketGradePipe,
    EnumToTextPipe,
    EnumToArrayPipe,
    WarningModalComponent,
  ],
  imports: [MaterialModule, CommonModule, NgOptimizedImage],
  exports: [
    PageNotFoundComponent,
    AppModalHeaderComponent,
    TalentmarketDatePipe,
    TalentmarketGradePipe,
    EnumToTextPipe,
    EnumToArrayPipe,
    WarningModalComponent,
  ],
  providers: [TalentmarketDatePipe, TalentmarketGradePipe],
})
export class SharedModule {}
