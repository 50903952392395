import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject, takeUntil, tap } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { TermsModel } from '../../models/terms.model';
import { AdminService } from '../../../admin/services/admin.service';
import { CampaignService } from '../../../admin/services/campaign.service';

@Component({
  selector: 'app-terms-and-conditions-page',
  templateUrl: './terms-and-conditions-page.component.html',
  styleUrls: ['./terms-and-conditions-page.component.scss'],
})
export class TermsAndConditionsPageComponent implements OnInit, OnDestroy {
  public selectedCampaignId: number | null = null;
  public selectedCompanyId: number | null = null;
  public selectedCampaignUrlId: string | null = null;
  public termsAndConditions: TermsModel = {
    id: null,
    html: '',
  };
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private adminService: AdminService,
    private campaignService: CampaignService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.selectedCompanyId = +this.router.url.split('/')[2];
    this.selectedCampaignId = +this.router.url.split('/')[5];
    this.route.paramMap.subscribe((params: ParamMap): void => {
      this.selectedCampaignUrlId = params.get('urlId');
    });
    if (this.selectedCampaignUrlId) {
      this.getTermsAndConditions(this.selectedCampaignUrlId);
    } else {
      this.getTermsAndConditionsPreview(this.selectedCampaignId);
    }
    if (this.selectedCompanyId) {
      this.adminService.getCompanyDetails(this.selectedCompanyId);
    }
  }

  public getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public getTermsAndConditions(campaignUrlId: string): void {
    this.campaignService
      .getTermsAndConditionsPage(campaignUrlId)
      .pipe(
        takeUntil(this.destroyed$),
        tap((responseData: TermsModel): void => {
          if (responseData !== null) {
            this.termsAndConditions = responseData;
          }
        })
      )
      .subscribe();
  }
  public getTermsAndConditionsPreview(campaignId: number): void {
    this.campaignService
      .getTermsAndConditionsPagePreview(campaignId)
      .pipe(
        takeUntil(this.destroyed$),
        tap((responseData: TermsModel): void => {
          if (responseData !== null) {
            this.termsAndConditions = responseData;
          }
        })
      )
      .subscribe();
  }

  public navigateToCampaignBuilder(): void {
    if (this.selectedCompanyId) {
      this.adminService.getCompanyDetails(this.selectedCompanyId);
      this.router
        .navigate([
          `admin/${this.selectedCompanyId}/create-campaign/quiz/${this.selectedCampaignId}`,
        ])
        .then();
    }
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
