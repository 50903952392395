import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxEditorModule } from 'ngx-editor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideUserIdleConfig } from 'angular-user-idle';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UserComponent } from './applicant/applicant-quiz/user/user.component';
import { NgOptimizedImage } from '@angular/common';
import { LoginComponent } from './login/login/login.component';
import { MaterialModule } from './material.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { PasswordChangeComponent } from './login/password-change/password-change.component';
import { SharedModule } from './shared.module';
import { OldHomePageComponent } from './public/old-home-page/old-home-page.component';
import { PolicyPrivacyPageComponent } from './applicant/applicant-quiz/policy-privacy-page/policy-privacy-page.component';
import { TermsAndConditionsPageComponent } from './applicant/applicant-quiz/terms-and-conditions-page/terms-and-conditions-page.component';
import { ThankYouPageComponent } from './applicant/applicant-quiz/thank-you-page/thank-you-page.component';
import { CampaignLandingPageComponent } from './applicant/applicant-quiz/campaign-landing-page/campaign-landing-page.component';
import { HeaderComponent } from './public/public-navigation/header/header.component';
import { FooterComponent } from './public/public-navigation/footer/footer.component';
import { HomePageComponent } from './public/home-page/home-page.component';
import { HeroSectionComponent } from './public/home-page/hero-section/hero-section.component';
import { PublicComponent } from './public/public.component';
import { FeaturedAdsSectionComponent } from './public/home-page/featured-ads-section/featured-ads-section.component';
import { StandardAdCardComponent } from './public/ad-cards/standard-ad-card/standard-ad-card.component';
import { PremiumAdCardComponent } from './public/ad-cards/premium-ad-card/premium-ad-card.component';
import { EliteAdCardComponent } from './public/ad-cards/elite-ad-card/elite-ad-card.component';
import { ContactUsPageComponent } from './public/contact-us-page/contact-us-page.component';
import { ThankYouForContactPageComponent } from './public/contact-us-page/thank-you-for-contact-page/thank-you-for-contact-page.component';
import { AboutUsPageComponent } from './public/about-us-page/about-us-page.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    LoginComponent,
    OldHomePageComponent,
    CampaignLandingPageComponent,
    ThankYouPageComponent,
    TermsAndConditionsPageComponent,
    PolicyPrivacyPageComponent,
    PasswordChangeComponent,
    HeaderComponent,
    FooterComponent,
    PublicComponent,
    HomePageComponent,
    HeroSectionComponent,
    FeaturedAdsSectionComponent,
    StandardAdCardComponent,
    PremiumAdCardComponent,
    EliteAdCardComponent,
    ContactUsPageComponent,
    ThankYouForContactPageComponent,
    AboutUsPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DragAndDropModule,
    DragDropModule,
    NgxEditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgOptimizedImage,
    MaterialModule,
    SharedModule,
    InfiniteScrollModule,
    GoogleSigninButtonModule,
    FontAwesomeModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideUserIdleConfig({ idle: 600, timeout: 300, ping: 120 }),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '435187945467-4ph09kjjb0m96qdqmnhh6ot73qb5lmua.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('813896500729195'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
