<button
  *ngIf="selectedCompanyId"
  (click)="navigateToCampaignBuilder()"
  class="m-2">
  Back to Campaign builder
</button>
<h1 class="user-quiz-title">{{ this.quizTitle }}</h1>
<div class="user-quiz-container">
  <form class="user-quiz-questions-holder">
    <div
      class="user-quiz-question-holder"
      *ngFor="let question of qasetss; let i = index">
      <label for="question{{ i }}" class="question-text"
        ><p>{{ question.question }}</p>
        <span *ngIf="question.required"> {{ '*required' | translate }}</span>
        <span *ngIf="!question.required">
          {{ '*Not required' | translate }}</span
        >
      </label>
      <div
        class="user-quiz-answer"
        *ngFor="let answer of question.answers; let j = index">
        <div
          *ngIf="
            answer.location !== 'dropdown' && question.displayStyle !== 'text'
          "
          class="user-quiz-location-answer">
          <input
            *ngIf="
              answer.location !== 'dropdown' &&
              question.displayStyle !== 'text' &&
              answer.location !== 'desc'
            "
            class="user-quiz-answer-button"
            type="{{ question.displayStyle }}"
            id="answer{{ i }}-{{ j }}"
            [value]="answer.mark"
            name="answer{{ i }}"
            (change)="
              selectedAnswers(
                quiz,
                question.question,
                i,
                answer.text,
                answer.id,
                answer.mark,
                answer.disqualifying,
                question.displayStyle,
                j,
                $event,
                answer.location
              )
            "
            required />
          <label class="user-quiz-answer-text" for="answer{{ i }}-{{ j }}">
            {{ answer.text
            }}<span *ngIf="answer.location === 'desc'">{{ '' }}</span>
          </label>
          <select
            *ngIf="answer.location === 'desc'"
            class="answer-location-select"
            [(ngModel)]="selectedLocation"
            name="selectedLocation"
            (change)="
              selectedAnswers(
                quiz,
                question.question,
                i,
                answer.text,
                answer.id,
                answer.mark,
                answer.disqualifying,
                question.displayStyle,
                j,
                $event,
                answer.location
              )
            ">
            <option value="" disabled selected>--Odaberi lokaciju--</option>
            <option
              class="answer-select-option"
              *ngFor="let dropdown of dropdowns; let k = index"
              [ngValue]="dropdown"
              [selected]="dropdown === selectedLocation">
              {{ dropdown.text }}
            </option>
          </select>
        </div>
        <div
          *ngIf="
            answer.location !== 'dropdown' && question.displayStyle === 'text'
          "
          class="answer-textarea-holder">
          <textarea
            class="answer-textarea-input"
            id="answer{{ i }}-{{ j }}"
            name="answer{{ i }}"
            [(ngModel)]="answer.text"
            rows="4"
            (change)="
              selectedAnswers(
                quiz,
                question.question,
                i,
                answer.text,
                answer.id,
                answer.mark,
                answer.disqualifying,
                question.displayStyle,
                j,
                $event,
                answer.location
              )
            "></textarea>
        </div>
      </div>
    </div>
  </form>
  <form class="user-quiz-contact-holder">
    <div class="contact-input-holder">
      <input
        type="text"
        class="contact-input"
        id="firstName"
        [(ngModel)]="candidate.firstName"
        name="firstName"
        [placeholder]="'name' | translate"
        required />
    </div>
    <div class="contact-input-holder">
      <input
        type="text"
        class="contact-input"
        id="lastName"
        [(ngModel)]="candidate.lastName"
        name="lastName"
        [placeholder]="'surname' | translate" />
    </div>
    <div class="contact-input-holder">
      <input
        type="text"
        class="contact-input"
        id="address"
        [(ngModel)]="candidate.address"
        name="address"
        [placeholder]="'address' | translate" />
    </div>
    <div class="contact-input-holder">
      <input
        type="text"
        class="contact-input"
        id="phone"
        [(ngModel)]="candidate.phone"
        name="phone"
        [placeholder]="'mobile' | translate" />
    </div>
    <div class="contact-input-holder">
      <input
        type="text"
        class="contact-input"
        id="email"
        [(ngModel)]="candidate.email"
        name="email"
        [placeholder]="'email' | translate" />
    </div>
  </form>
  <div class="user-quiz-footer">
    <div class="user-quiz-privacy">
      <input type="checkbox" name="radio" [(ngModel)]="candidate.privola" />
      <div class="user-quiz-privacy-checkbox">
        {{ gdprAcceptance }}
        <a [href]="'/privacy-policy/' + this.quiz?.url" target="_blank">
          {{ privacyPolicyTitle }} </a
        >&nbsp;
        {{ gdprAcceptance2 }}
        <a [href]="'/terms-and-conditions/' + this.quiz?.url" target="_blank"
          >{{ termsTitle }}
        </a>
      </div>
    </div>
    <div class="button-container">
      <button class="cat-button" (click)="submitApplication(candidate)">
        {{ 'Send Application' | translate }}
      </button>
    </div>
    <div class="footer-note-container">
      <p class="footer-note">{{ safetyMessage }}</p>
    </div>
  </div>
</div>
