import { Component } from '@angular/core';

@Component({
  selector: 'app-old-home-page',
  templateUrl: './old-home-page.component.html',
  styleUrls: ['./old-home-page.component.scss'],
})
export class OldHomePageComponent {
  scrollToHome() {
    const aboutUs = document.querySelector('#home');
    if (aboutUs) {
      aboutUs.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToContactUs() {
    const aboutUs = document.querySelector('#contact-us');
    if (aboutUs) {
      aboutUs.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToAboutUs() {
    const aboutUs = document.querySelector('#about-us');
    if (aboutUs) {
      aboutUs.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToHowItWorks() {
    const aboutUs = document.querySelector('#how-it-works');
    if (aboutUs) {
      aboutUs.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
