<div class="display-flex-with-align-center-and-justify-center">
  <mat-card
    class="login-card display-flex-with-align-center-and-justify-center">
    <mat-card-header class="header-holder">
      <img
        priority
        ngSrc="assets/talentmarket.logo.webp"
        alt="talentmarket."
        class="logo-card"
        height="100"
        width="250" />
    </mat-card-header>
    <mat-card-content *ngIf="passwordChangeFormGroup">
      <form
        action=""
        [formGroup]="passwordChangeFormGroup"
        (ngSubmit)="passwordChange()"
        class="form-holder">
        <mat-form-field appearance="fill" class="width-full">
          <input
            matInput
            id="newPasswordFirst"
            required
            type="password"
            formControlName="newPasswordFirst"
            placeholder="New Password" />
        </mat-form-field>
        <mat-form-field appearance="fill" class="width-full">
          <input
            matInput
            id="newPasswordSecond"
            required
            type="password"
            formControlName="newPasswordSecond"
            placeholder="New Password" />
        </mat-form-field>
        <button
          mat-raised-button
          color="primary"
          class="change-password-btn"
          [disabled]="isChangeDisabled">
          Change Password
        </button>
      </form>
    </mat-card-content>
    <mat-card-footer class="login-footer">
      Let's find great candidates!
    </mat-card-footer>
  </mat-card>
</div>
