<div class="display-flex-with-align-center-and-justify-center">
  <mat-card class="error-card">
    <mat-card-header class="header-holder">
      <img
        ngSrc="assets/talentmarket.logo.webp"
        alt="talentmarket."
        class="logo-card"
        height="100"
        width="250" />
    </mat-card-header>
    <button
      mat-raised-button
      class="back-to-login"
      color="primary"
      (click)="backToLogin()">
      Back to login
    </button>
  </mat-card>
</div>
