import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { faBriefcase, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public faBriefcase: IconDefinition = faBriefcase;

  // TODO handle isMenuOpen in sharedService so its accessible from other components
  public isMenuOpen = false;

  constructor(private router: Router) {}
  public toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }
  public navigateToHome(): void {
    this.router.navigate(['/home']).then((): void => {
      this.isMenuOpen = false;
    });
  }
  public navigateToAboutUs(): void {
    this.router.navigate(['/about-us']).then((): void => {
      this.isMenuOpen = false;
    });
  }
  public navigateToContactUs(): void {
    this.router.navigate(['/contact-us']).then((): void => {
      this.isMenuOpen = false;
    });
  }
  public navigateToBusinessLogin(): void {
    this.router.navigate(['/signin']).then((): void => {
      this.isMenuOpen = false;
    });
  }
}
