import { Injectable } from '@angular/core';
import { catchError, Subject, tap, throwError, BehaviorSubject } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';

import { environment } from '../../../environments/environment';
import { WarningModalComponent } from '../../shared/warning-modal/warning-modal.component';
import { HomeAdsRequestModel } from '../models/home-ads-request.model';
import { FeaturedAdsResponseModel } from '../models/featured-ads-response.model';

@Injectable({
  providedIn: 'root',
})
export class AdsService {
  private apiServerUrl: string = environment.apiBaseUrl;
  public page$ = new BehaviorSubject<number>(0);
  public featuredAds$: Subject<FeaturedAdsResponseModel> =
    new Subject<FeaturedAdsResponseModel>();
  public ads$: Subject<FeaturedAdsResponseModel> =
    new Subject<FeaturedAdsResponseModel>();

  public adsReq: HomeAdsRequestModel = {
    locationCampaignTitle: '',
    page: this.page$.value,
    size: 18,
  };

  constructor(
    private http: HttpClient,
    private dialog: MatDialog
  ) {}

  public getAllAds(): void {
    const params: HttpParams = new HttpParams()
      .append('locationCampaignTitle', this.adsReq.locationCampaignTitle)
      .append('size', this.adsReq.size)
      .append('page', this.adsReq.page);
    this.http
      .get<FeaturedAdsResponseModel>(`${this.apiServerUrl}/public/ads`, {
        params,
      })
      .pipe(
        catchError(error => {
          this.handleError(error);
          return throwError(() => new Error(error));
        }),
        tap((featuredAdsResponse: FeaturedAdsResponseModel): void => {
          this.ads$.next(featuredAdsResponse);
        })
      )
      .subscribe();
  }

  public setLocationAndJobFilter(filterValue: string): void {
    this.adsReq.locationCampaignTitle = filterValue;
    this.page$.next(0);
    this.getAllAds();
  }

  private handleError(error: HttpErrorResponse): void {
    const errorMessage = error.error.message;
    this.dialog.open(WarningModalComponent, {
      data: errorMessage,
    });
  }
}
