import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumToText',
})
export class EnumToTextPipe implements PipeTransform {
  public transform(value: string): string {
    return value.toLowerCase().split('_').join(' ').toUpperCase();
  }
}
