import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { catchError, Subject, tap, throwError } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';

import { environment } from '../../../environments/environment';
import { UserBasicModel } from '../models/user-basic.model';
import { CompaniesRequestModel } from '../models/companies-request.model';
import { WarningModalComponent } from '../../shared/warning-modal/warning-modal.component';
import { NewCompanyModel } from '../models/new-company.model';
import { CompanyBasicResponseModel } from '../models/company-basic-response.model';
import { CompanyDetailsModel } from '../models/company-details.model';
import { CompanyDetailsUpdateModel } from '../models/company-details-update.model';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private apiServerUrl: string = environment.apiBaseUrl;
  public selectedCompanyResponse$: Subject<CompanyDetailsModel | null> =
    new Subject<CompanyDetailsModel | null>();
  public companiesResponse$: Subject<CompanyBasicResponseModel> =
    new Subject<CompanyBasicResponseModel>();

  constructor(
    private http: HttpClient,
    private dialog: MatDialog
  ) {}

  public companiesReq: CompaniesRequestModel = {
    name: '',
    sort: 'name,desc',
    page: 0,
    size: 10,
  };
  public getAllCompanies(): void {
    const params: HttpParams = new HttpParams()
      .append('name', this.companiesReq.name)
      .append('sort', this.companiesReq.sort)
      .append('size', this.companiesReq.size)
      .append('page', this.companiesReq.page);
    this.http
      .get<CompanyBasicResponseModel>(`${this.apiServerUrl}/company`, {
        params,
      })
      .pipe(
        catchError(error => {
          this.handleError(error);
          return throwError(() => new Error(error));
        }),
        tap((companies: CompanyBasicResponseModel): void => {
          this.companiesResponse$.next(companies);
        })
      )
      .subscribe();
  }
  public setClientsFilter(filterValue: string): void {
    this.companiesReq.name = filterValue;
    this.getAllCompanies();
  }
  public setClientsSort(clientsSort: Sort): void {
    const property = clientsSort.active;
    const direction = clientsSort.direction;
    this.companiesReq.sort = `${property},${direction}`;
    this.getAllCompanies();
  }
  public setClientsPagination(pagination: PageEvent): void {
    this.companiesReq.size = pagination.pageSize;
    this.companiesReq.page = pagination.pageIndex;
    this.getAllCompanies();
  }

  public getCompanyDetails(companyId: number): void {
    this.http
      .get<CompanyDetailsModel>(`${this.apiServerUrl}/company/${companyId}`)
      .pipe(
        catchError(error => {
          this.handleError(error);
          return throwError(() => new Error(error));
        }),
        tap((companyDetails: CompanyDetailsModel): void => {
          this.selectedCompanyResponse$.next(companyDetails);
        })
      )
      .subscribe();
  }
  public createCompany(newCompany: NewCompanyModel): void {
    this.http
      .post<void>(`${this.apiServerUrl}/company`, newCompany)
      .pipe(
        catchError(error => {
          this.handleError(error);
          return throwError(() => new Error(error));
        })
      )
      .subscribe(() => this.getAllCompanies());
  }
  public updateCompany(companyDetails: CompanyDetailsUpdateModel): void {
    this.http
      .patch<void>(
        `${this.apiServerUrl}/company/${companyDetails.id}`,
        companyDetails
      )
      .pipe(
        catchError(error => {
          this.handleError(error);
          return throwError(() => new Error(error));
        })
      )
      .subscribe(() => {
        this.getAllCompanies();
        this.getCompanyDetails(companyDetails.id);
      });
  }
  public createNewUser(userInfo: UserBasicModel): void {
    this.http
      .post<UserBasicModel>(`${this.apiServerUrl}/auth/register`, userInfo)
      .pipe(
        catchError(error => {
          this.handleError(error);
          return throwError(() => new Error(error));
        })
      )
      .subscribe();
  }

  private handleError(error: HttpErrorResponse): void {
    const errorMessage = error.error.message;
    this.dialog.open(WarningModalComponent, {
      data: errorMessage,
    });
  }
}
