import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil, tap } from 'rxjs';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { AdminService } from '../../../admin/services/admin.service';
import { UserAnswer } from '../../models/user-answer.model';
import { UserQuiz } from '../../models/user-quiz.model';
import { Answer } from '../../../shared/models/answer.model';
import { CandidateModel } from '../../models/candidate.model';
import { WarningModalComponent } from '../../../shared/warning-modal/warning-modal.component';
import { CampaignService } from '../../../admin/services/campaign.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
// TODO refactor ts and cleanup css
export class UserComponent implements OnInit, OnDestroy {
  public selectedCampaignId: number | null = null;
  public selectedCompanyId: number | null = null;
  public selectedCampaignUrlId: string | null = null;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  notFilled!: boolean;
  selectedLocation: any = '';
  gdprCallToAction: any;
  gdprAcceptance: any;
  privacyPolicyTitle: any;
  termsTitle: any;
  gdprAcceptance2: any;
  safetyMessage: any;
  dropdowns!: UserAnswer[];
  public quiz: UserQuiz | undefined;
  public qasetss: any;
  public quizTitle = '';
  public candidate: CandidateModel = {
    id: null,
    quizId: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    date: '',
    mark: '',
    privola: false,
    checked: false,
    atsListTitle: '',
    atsListId: null,
    atsIndex: null,
    description: '',
    comments: ([] = []),
    candidateAnswers: ([] = []),
  };
  imageId1!: string;

  selectedAnswer: { question: string; answerText: string }[] = [];

  messsage = 'Ispunite cijeli kviz';
  privolaWarning =
    'Potvrdite suglasnost o korištenju Vaših podataka u svrhu zapošljavanja';

  constructor(
    private campaignService: CampaignService,
    private adminService: AdminService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private customTitle: Title,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.selectedCompanyId = +this.router.url.split('/')[2];
    this.selectedCampaignId = +this.router.url.split('/')[5];
    this.route.paramMap.subscribe((params: ParamMap): void => {
      this.selectedCampaignUrlId = params.get('urlId');
    });
    if (this.selectedCampaignUrlId) {
      this.getCampaignQuizByUrlId(this.selectedCampaignUrlId);
    } else {
      this.getCampaignQuizById(this.selectedCampaignId);
    }
    if (this.selectedCompanyId) {
      this.adminService.getCompanyDetails(this.selectedCompanyId);
    }
  }

  public getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  selectedAnswers(
    quiz: any,
    question: string,
    questionIndex: number,
    answer: string,
    answerId: number,
    mark: number,
    disqualifying: string,
    displayStyle: string,
    answerIndex: number,
    event: any,
    location: string
  ) {
    const i = this.candidate.candidateAnswers.findIndex(
      e => e.question === question
    ); // Check if question exist, if
    const index = this.candidate.candidateAnswers.length;
    if (i <= -1) {
      // if question doesn't exist didn't find i will be -1, and then you push, which will be at index of lenght
      /* vendors contains the element we're looking for, at index "i" */

      this.candidate.candidateAnswers.push({
        question: '',
        answer: '',
        answerMark: 0,
        disqualifying: '',
        answerId: 0,
      });

      if (location !== 'desc') {
        // v2 -> if question isn't "odaberi lokaciju:" (dropdown) add as usual
        this.candidate.candidateAnswers[index].question = question;
        this.candidate.candidateAnswers[index].answer = answer;
        this.candidate.candidateAnswers[index].answerMark = mark;
        this.candidate.candidateAnswers[index].disqualifying = disqualifying;
        this.candidate.candidateAnswers[index].answerId = answerId;
        this.candidate.quizId = quiz.id;
      } else {
        // v2 -> if question is "odaberi lokaciju:" (dropdown) add this.selectedLocation
        this.candidate.candidateAnswers[index].question = question;
        this.candidate.candidateAnswers[index].answer =
          this.selectedLocation.text; //added selectedLocation which is from dropdown
        this.candidate.candidateAnswers[index].answerMark =
          this.selectedLocation.mark;
        this.candidate.candidateAnswers[index].disqualifying =
          this.selectedLocation.disqualifying;
        this.candidate.candidateAnswers[index].answerId =
          this.selectedLocation.id;
        this.candidate.quizId = quiz.id;
      }
    } else {
      // if question exist, at index i changed the answer
      if (displayStyle == 'radio' && location !== 'desc') {
        //v2 -> ako je radio i postoji pitanje, a pitanje nije desc onda dodaj answer
        this.candidate.candidateAnswers[i].answer = answer;
        this.candidate.candidateAnswers[i].answerMark = mark;
        this.candidate.candidateAnswers[i].disqualifying = disqualifying;
        this.candidate.candidateAnswers[i].answerId = answerId;
        this.candidate.quizId = quiz.id;
      } else if (displayStyle == 'radio' && location == 'desc') {
        //v2 -> ako je radio i postoji pitanje, a pitanje je desc onda dodaj answer
        this.candidate.candidateAnswers[i].answer = this.selectedLocation.text;
        this.candidate.candidateAnswers[i].answerMark =
          this.selectedLocation.mark;
        this.candidate.candidateAnswers[i].disqualifying =
          this.selectedLocation.disqualifying;
        this.candidate.candidateAnswers[i].answerId = this.selectedLocation.id;
        this.candidate.quizId = quiz.id;
      } else if (displayStyle == 'text') {
        this.candidate.candidateAnswers[i].answer = answer;
        this.candidate.candidateAnswers[i].answerMark = mark;
        this.candidate.candidateAnswers[i].disqualifying = disqualifying;
        this.candidate.candidateAnswers[i].answerId = answerId;
        this.candidate.quizId = quiz.id;
      }
      if (displayStyle == 'checkbox') {
        const a = this.candidate.candidateAnswers.findIndex(
          a => a.answer === answer
        );
        if (a > -1) {
          // if it exist but now state is false it needs to erase, if it doesn't exist upper part will add it
          this.candidate.candidateAnswers.splice(a, 1);
        } else {
          this.candidate.candidateAnswers.push({
            question: '',
            answer: '',
            answerMark: 0,
            disqualifying: '',
            answerId: 0,
          });

          if (location == 'da') {
            // v2 -> if question isn't "odaberi lokaciju:" (dropdown) add as usual
            this.candidate.candidateAnswers[index].question = question;
            this.candidate.candidateAnswers[index].answer = answer;
            this.candidate.candidateAnswers[index].answerMark = mark;
            this.candidate.candidateAnswers[index].disqualifying =
              disqualifying;
            this.candidate.candidateAnswers[index].answerId = answerId;
            this.candidate.quizId = quiz.id;
          } else {
            // v2 -> if question is "odaberi lokaciju:" (dropdown) add this.selectedLocation
            this.candidate.candidateAnswers[index].question = question;
            this.candidate.candidateAnswers[index].answer =
              this.selectedLocation.text; //added selectedLocation which is from dropdown
            this.candidate.candidateAnswers[index].answerMark = mark;
            this.candidate.candidateAnswers[index].disqualifying =
              disqualifying;
            this.candidate.candidateAnswers[index].answerId = answerId;
            this.candidate.quizId = quiz.id;
          }
        }
      }
    }
  }

  public getCampaignQuizById(campaignId: number): void {
    this.campaignService
      .getCampaignQuizForUserByCampaignId(campaignId)
      .pipe(
        takeUntil(this.destroyed$),
        tap((responseData: UserQuiz): void => {
          this.quiz = responseData;
          this.customTitle.setTitle('TM | ' + this.quiz.companyTitle);
          this.imageId1 = responseData.imageId1;
          this.dropdowns = this.filterAnswersForDropdown(responseData);
          this.qasetss = this.quiz.qasets;
          this.quizTitle = this.quiz.title;
          this.translate.setDefaultLang(this.quiz.language);
          this.gdprCallToAction = responseData.gdprCallToAction;
          this.gdprAcceptance = responseData.gdprAccepptance;
          this.privacyPolicyTitle = responseData.privacyPolicyTitle;
          this.termsTitle = responseData.termsTitle;
          this.gdprAcceptance2 = responseData.gdprAccepptance2;
          this.safetyMessage = responseData.safetyMessage;
        })
      )
      .subscribe();
  }

  public getCampaignQuizByUrlId(campaignUrlId: string): void {
    this.campaignService
      .getCampaignQuizForUserByCampaignUrlId(campaignUrlId)
      .pipe(
        takeUntil(this.destroyed$),
        tap((responseData: UserQuiz): void => {
          this.quiz = responseData;
          this.customTitle.setTitle('TM | ' + this.quiz.companyTitle);
          this.imageId1 = responseData.imageId1;
          this.dropdowns = this.filterAnswersForDropdown(responseData);
          this.qasetss = this.quiz.qasets;
          this.quizTitle = this.quiz.title;
          this.translate.setDefaultLang(this.quiz.language);
          this.gdprCallToAction = responseData.gdprCallToAction;
          this.gdprAcceptance = responseData.gdprAccepptance;
          this.privacyPolicyTitle = responseData.privacyPolicyTitle;
          this.termsTitle = responseData.termsTitle;
          this.gdprAcceptance2 = responseData.gdprAccepptance2;
          this.safetyMessage = responseData.safetyMessage;
        })
      )
      .subscribe();
  }

  filterAnswersForDropdown(quiz: UserQuiz): Answer[] {
    return quiz.qasets.flatMap(qaSet =>
      qaSet.answers.filter(answer => answer.location === 'dropdown')
    );
  }

  public submitApplication(candidate: CandidateModel): void {
    if (this.validateFormNew(this.candidate)) {
      this.campaignService
        .submitCampaignQuizApplication(candidate)
        .pipe(
          takeUntil(this.destroyed$),
          tap((responseData: CandidateModel): void => {
            candidate = responseData;
          })
        )
        .subscribe(() => {
          this.router.navigate([`/thank-you/${this.quiz?.url}`]).then();
        });
    } else {
      this.openWarningModal('Info input not filled!');
    }
  }
  validateFormNew(candidate: CandidateModel): boolean {
    const uniqueQuestions = new Set<string>();
    for (const answer of this.candidate.candidateAnswers) {
      uniqueQuestions.add(answer.question);
    }

    this.notFilled = true;
    for (const question of this.qasetss) {
      if (question.required) {
        if (!uniqueQuestions.has(question.question)) {
          this.openWarningModal(
            'Answer all the questions to be able to apply.'
          );
          return false;
        }
      }
    }
    if (
      !candidate.firstName ||
      !candidate.lastName ||
      !candidate.address ||
      !candidate.phone ||
      !candidate.email
    ) {
      this.openWarningModal(
        'Fill in all personal information data fields to be able to apply.'
      );
      return false;
    }
    if (!this.candidate.privola) {
      this.openWarningModal(this.privolaWarning);
      return false;
    }
    return true;
  }

  public openWarningModal(message: string): void {
    this.dialog.open(WarningModalComponent, {
      data: message,
    });
  }

  public navigateToCampaignBuilder(): void {
    if (this.selectedCompanyId) {
      this.adminService.getCompanyDetails(this.selectedCompanyId);
      this.router
        .navigate([
          `admin/${this.selectedCompanyId}/create-campaign/quiz/${this.selectedCampaignId}`,
        ])
        .then();
    }
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
