import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {
  faPaperPlane,
  faPhoneAlt,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.scss'],
})
export class AboutUsPageComponent {
  public faPaperPlane: IconDefinition = faPaperPlane;
  public faPhoneAlt: IconDefinition = faPhoneAlt;
  constructor(private router: Router) {}
  public navigateToContactUs(): void {
    this.router.navigate(['/contact-us']).then();
  }
}
