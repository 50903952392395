import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-modal-header',
  templateUrl: './app-modal-header.component.html',
  styleUrls: ['./app-modal-header.component.scss'],
})
export class AppModalHeaderComponent<T> {
  @Input() public leftSideText = '';

  constructor(public dialogRef: MatDialogRef<T>) {}
}
