<div class="header-container max-container-width">
  <nav
    class="container navbar navbar-expand-lg navbar-light public-navigation-holder">
    <img
      priority
      ngSrc="assets/talentmarket.logo.svg"
      (click)="navigateToHome()"
      alt="talentmarket."
      class="header-logo"
      height="70"
      width="210" />
    <button
      class="navbar-toggler"
      type="button"
      (click)="toggleMenu()"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      [ngClass]="{ collapse: !isMenuOpen, 'navbar-collapse': true }"
      style="width: auto; justify-content: center"
      id="navbarNav">
      <div class="navigation-list-holder">
        <ul class="navigation-list">
          <li class="nav-item align-content-center justify-content-center m-1">
            <button class="header-navigation-button" (click)="navigateToHome()">
              Nađi posao
            </button>
          </li>
          <li class="nav-item align-content-center justify-content-center m-1">
            <button
              class="header-navigation-button"
              (click)="navigateToAboutUs()">
              O nama
            </button>
          </li>
          <li class="nav-item align-content-center justify-content-center m-1">
            <button
              class="header-navigation-button"
              (click)="navigateToContactUs()">
              Objavite oglas
            </button>
          </li>
        </ul>
        <button
          class="basic-black-button business-list-button-mobile"
          (click)="navigateToBusinessLogin()">
          <fa-icon [icon]="faBriefcase"></fa-icon>&nbsp; Poslovni korisnici
        </button>
      </div>
    </div>
  </nav>
  <button
    class="basic-black-button business-list-button-desktop"
    (click)="navigateToBusinessLogin()">
    <fa-icon [icon]="faBriefcase"></fa-icon>&nbsp; Poslovni korisnici
  </button>
</div>
