<div class="premium-ad-card">
  <div class="premium-ad-badge">Premium</div>
  <div class="premium-ad-top-holder">
    <div class="premium-ad-logo-holder">
      <img
        class="premium-ad-logo"
        src="data:image/png;base64, {{ ad.companyLogo }}"
        alt="{{ ad.companyName }} logo" />
    </div>
    <div class="premium-ad-top-right-holder">
      <p>{{ ad.companyName }}</p>
      <div class="ad-card-location-holder">
        <span class="material-symbols-outlined"> location_on </span>&nbsp;{{
          ad.jobLocation
        }}
      </div>
    </div>
  </div>
  <div class="premium-ad-bot-holder">
    <p>{{ ad.campaignTitle }}</p>
  </div>
  <button
    class="apply-to-premium-campaign"
    (click)="navigateToCampaignLandingPage(ad.campaignUrlId)">
    Brza prijava
  </button>
</div>
