import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from '../login/auth.service';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { AccessTokenModel } from '../login/models/access-token.model';
import { filter, take, switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(public authService: AuthService) {}

  intercept(
    request: HttpRequest<AccessTokenModel>,
    next: HttpHandler
  ): Observable<HttpEvent<AccessTokenModel>> {
    const accessToken = this.authService.getAccessToken();
    if (accessToken && request.url !== 'api/v1/auth/refresh-token') {
      request = this.addToken(request, accessToken);
    }
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else if (error instanceof HttpErrorResponse && error.status === 403) {
          this.authService.logout();
          return throwError(() => error);
        } else {
          return throwError(() => error);
        }
      })
    );
  }
  private addToken(
    request: HttpRequest<AccessTokenModel>,
    token: string
  ): HttpRequest<AccessTokenModel> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(
    request: HttpRequest<{ token: string }>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap((responseTokenModel: { token: string }) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(responseTokenModel.token);
          return next.handle(this.addToken(request, responseTokenModel.token));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(accessToken => {
          return next.handle(this.addToken(request, accessToken));
        })
      );
    }
  }
}
