<div class="display-flex-with-align-center-and-justify-center">
  <mat-card
    class="login-card display-flex-with-align-center-and-justify-center">
    <mat-card-header class="header-holder">
      <img
        priority
        ngSrc="assets/talentmarket.logo.svg"
        routerLink="/home"
        alt="talentmarket."
        class="logo-card cursor-pointer"
        height="100"
        width="250" />
    </mat-card-header>
    <mat-card-content *ngIf="loginFormGroup">
      <form
        action=""
        [formGroup]="loginFormGroup"
        (ngSubmit)="signIn()"
        class="form-holder">
        <mat-form-field appearance="fill" class="width-full">
          <input
            matInput
            id="email"
            required
            formControlName="email"
            placeholder="Email"
            autocomplete="username" />
        </mat-form-field>
        <mat-error
          *ngIf="
            loginFormGroup.get('email')?.invalid && loginFormGroup.touched
          ">
          Email is required
        </mat-error>
        <mat-form-field appearance="fill" class="width-full">
          <input
            matInput
            id="password"
            required
            type="password"
            formControlName="password"
            placeholder="Password"
            autocomplete="current-password" />
        </mat-form-field>
        <mat-error
          *ngIf="
            loginFormGroup.get('password')?.invalid && loginFormGroup.touched
          ">
          Password is required
        </mat-error>
        <button mat-raised-button color="primary" class="login-btn">
          Login
        </button>
      </form>
    </mat-card-content>
    <mat-card-footer class="login-footer">
      Let's find great candidates!
    </mat-card-footer>
  </mat-card>
</div>
