import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AdModel } from '../../models/ad.model';
@Component({
  selector: 'app-premium-ad-card',
  templateUrl: './premium-ad-card.component.html',
  styleUrls: ['./premium-ad-card.component.scss'],
})
export class PremiumAdCardComponent {
  @Input() ad!: AdModel;
  constructor(private router: Router) {}
  public navigateToCampaignLandingPage(campaignUrlId: string): void {
    const url: string = this.router.serializeUrl(
      this.router.createUrlTree([`clp/${campaignUrlId}`])
    );
    window.open(url, '_blank');
  }
}
