import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QuizModel } from '../../applicant/models/quiz.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ThankYouModel } from '../../applicant/models/thank-you.model';
import { PrivacyPolicyModel } from '../../applicant/models/privacy-policy.model';
import { TermsModel } from '../../applicant/models/terms.model';
import { LandingPage } from '../../applicant/models/landing-page.model';
import { UserQuiz } from '../../applicant/models/user-quiz.model';
import { CandidateModel } from '../../applicant/models/candidate.model';

@Injectable({
  providedIn: 'root',
})
export class CampaignService {
  private apiServerUrl = environment.apiBaseUrl;
  constructor(private http: HttpClient) {}

  // Campaign Quiz Commands
  public deleteAnswer(answerId: number): Observable<void> {
    return this.http.delete<void>(
      `${this.apiServerUrl}/quiz/answer/${answerId}`
    );
  }
  public deleteQASet(qasetId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiServerUrl}/quiz/qaset/${qasetId}`);
  }

  public getQuizTemplatesForCompany(
    companyId: number
  ): Observable<QuizModel[]> {
    return this.http.get<QuizModel[]>(
      `${this.apiServerUrl}/template/quiz/all/company/${companyId}`
    );
  }
  public addCampaignForCompany(
    quiz: QuizModel,
    companyId: number
  ): Observable<QuizModel> {
    return this.http.post<QuizModel>(
      `${this.apiServerUrl}/${companyId}/quiz`,
      quiz
    );
  }
  public updateCampaignForCompany(
    quiz: QuizModel,
    companyId: number
  ): Observable<QuizModel> {
    return this.http.patch<QuizModel>(
      `${this.apiServerUrl}/${companyId}/quiz`,
      quiz
    );
  }
  public deleteCampaignForCompany(quizId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiServerUrl}/quiz/${quizId}`);
  }

  // Campaign Landing Page
  public getCampaignLandingPagePreview(
    campaignId: number
  ): Observable<LandingPage> {
    return this.http.get<LandingPage>(
      `${this.apiServerUrl}/landing-page/quiz/${campaignId}`
    );
  }
  public addCampaignLandingPage(
    landingPage: LandingPage,
    campaignId: number
  ): Observable<LandingPage> {
    return this.http.post<LandingPage>(
      `${this.apiServerUrl}/landing-page/${campaignId}`,
      landingPage
    );
  }
  public getCampaignLandingPage(
    campaignUrlId: string
  ): Observable<LandingPage> {
    return this.http.get<LandingPage>(
      `${this.apiServerUrl}/public/landing-page/quiz-url/${campaignUrlId}`
    );
  }

  // Privacy Policy Page
  public getPrivacyPolicyPagePreview(
    campaignId: number
  ): Observable<PrivacyPolicyModel> {
    return this.http.get<PrivacyPolicyModel>(
      `${this.apiServerUrl}/privacy-policy/quiz/${campaignId}`
    );
  }
  public addPrivacyPolicyPage(
    privacyPolicy: PrivacyPolicyModel,
    campaignId: number
  ): Observable<PrivacyPolicyModel> {
    return this.http.post<PrivacyPolicyModel>(
      `${this.apiServerUrl}/privacy-policy/${campaignId}`,
      privacyPolicy
    );
  }
  public getPrivacyPolicyPage(
    campaignId: number
  ): Observable<PrivacyPolicyModel> {
    return this.http.get<PrivacyPolicyModel>(
      `${this.apiServerUrl}/public/privacy-policy/quiz-url/${campaignId}`
    );
  }
  // Terms and Conditions
  public getTermsAndConditionsPagePreview(
    campaignId: number
  ): Observable<TermsModel> {
    return this.http.get<TermsModel>(
      `${this.apiServerUrl}/terms/quiz/${campaignId}`
    );
  }
  public addTermsAndConditionsPage(
    terms: TermsModel,
    campaignId: number
  ): Observable<TermsModel> {
    return this.http.post<TermsModel>(
      `${this.apiServerUrl}/terms/quiz/${campaignId}`,
      terms
    );
  }
  public getTermsAndConditionsPage(
    campaignUrlId: string
  ): Observable<TermsModel> {
    return this.http.get<TermsModel>(
      `${this.apiServerUrl}/public/terms/quiz-url/${campaignUrlId}`
    );
  }
  // Thank You Page
  public getThankYouPagePreview(campaignId: number): Observable<ThankYouModel> {
    return this.http.get<ThankYouModel>(
      `${this.apiServerUrl}/thank-you/quiz/${campaignId}`
    );
  }
  public addThankYouPage(
    thankYou: ThankYouModel,
    campaignId: number
  ): Observable<ThankYouModel> {
    return this.http.post<ThankYouModel>(
      `${this.apiServerUrl}/thank-you/quiz/${campaignId}`,
      thankYou
    );
  }
  public getThankYouPage(campaignUrlId: string): Observable<ThankYouModel> {
    return this.http.get<ThankYouModel>(
      `${this.apiServerUrl}/public/thank-you/quiz-url/${campaignUrlId}`
    );
  }
  // Campaign Quiz
  public getCampaignQuizById(quizId: number): Observable<QuizModel> {
    return this.http.get<QuizModel>(`${this.apiServerUrl}/quiz/${quizId}`);
  }
  public getCampaignQuizForUserByCampaignId(
    campaignId: number
  ): Observable<UserQuiz> {
    return this.http.get<UserQuiz>(
      `${this.apiServerUrl}/public/quiz/${campaignId}`
    );
  }
  public getCampaignQuizForUserByCampaignUrlId(
    campaignUrlId: string
  ): Observable<UserQuiz> {
    return this.http.get<UserQuiz>(
      `${this.apiServerUrl}/public/quiz/quiz-path/${campaignUrlId}`
    );
  }
  public submitCampaignQuizApplication(
    candidate: CandidateModel
  ): Observable<CandidateModel> {
    return this.http.post<CandidateModel>(
      `${this.apiServerUrl}/public/candidate`,
      candidate
    );
  }
}
