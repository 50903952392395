import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
})
export class PasswordChangeComponent implements OnInit, OnDestroy {
  public passwordChangeFormGroup: FormGroup | null = null;
  public isChangeDisabled = true;
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService
  ) {
    this.passwordChangeFormGroup = this.formBuilder.group({
      newPasswordFirst: [null, Validators.required],
      newPasswordSecond: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.subscribeToFormChanges();
  }

  private subscribeToFormChanges(): void {
    if (this.passwordChangeFormGroup) {
      this.passwordChangeFormGroup.valueChanges
        .pipe(
          takeUntil(this.destroyed$),
          tap(
            (inputValues: {
              newPasswordFirst: string;
              newPasswordSecond: string;
            }): void => {
              this.isChangeDisabled = !(
                inputValues.newPasswordFirst ===
                  inputValues.newPasswordSecond &&
                inputValues.newPasswordSecond.length >= 8
              );
            }
          )
        )
        .subscribe();
    }
  }

  public passwordChange(): void {
    if (this.passwordChangeFormGroup) {
      this.authService.changePassword({
        newPassword: this.passwordChangeFormGroup.value.newPasswordSecond,
      });
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
