import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import { ViewportScroller } from '@angular/common';

import { AdsService } from '../../services/ads.service';

@Component({
  selector: 'app-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss'],
})
export class HeroSectionComponent implements OnInit, OnDestroy {
  public searchAdsInput: FormControl;
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private adsService: AdsService,
    private viewportScroller: ViewportScroller
  ) {
    adsService.getAllAds();
    this.searchAdsInput = new FormControl('');
  }

  ngOnInit(): void {
    this.subscribeToFilterChanges();
  }
  private subscribeToFilterChanges(): void {
    this.searchAdsInput.valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(500),
        distinctUntilChanged(),
        tap((filterValue: string): void => {
          this.adsService.setLocationAndJobFilter(filterValue);
        })
      )
      .subscribe();
  }

  public navigateToAdsAndSearch(): void {
    this.adsService.setLocationAndJobFilter(this.searchAdsInput.value);
    this.viewportScroller.scrollToAnchor('featured-ads-anchor');
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
