import { Pipe, PipeTransform } from '@angular/core';
import { ApplicantDetailsModel } from '../client/models/applicant-details.model';

@Pipe({
  name: 'talentmarketGradePipe',
})
export class TalentmarketGradePipe implements PipeTransform {
  public transform(
    allApplicants: ApplicantDetailsModel[] | null,
    mark: number
  ): ApplicantDetailsModel[] | null {
    if (!allApplicants) {
      return allApplicants;
    }
    return allApplicants.filter(
      (applicant: ApplicantDetailsModel): boolean => applicant.mark === mark
    );
  }
}
