import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subject, takeUntil, tap } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { PrivacyPolicyModel } from '../../models/privacy-policy.model';
import { AdminService } from '../../../admin/services/admin.service';
import { CampaignService } from '../../../admin/services/campaign.service';

@Component({
  selector: 'app-policy-privacy-page',
  templateUrl: './policy-privacy-page.component.html',
  styleUrls: ['./policy-privacy-page.component.scss'],
})
export class PolicyPrivacyPageComponent implements OnInit, OnDestroy {
  public selectedCampaignId: number | null = null;
  public selectedCompanyId: number | null = null;
  public selectedCampaignUrlId: string | null = null;
  public privacyPolicy: PrivacyPolicyModel = {
    id: null,
    html: '',
  };
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private adminService: AdminService,
    private campaignService: CampaignService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.selectedCompanyId = +this.router.url.split('/')[2];
    this.selectedCampaignId = +this.router.url.split('/')[5];
    this.route.paramMap.subscribe((params: ParamMap): void => {
      this.selectedCampaignUrlId = params.get('urlId');
    });
    if (this.selectedCampaignUrlId) {
      this.getPrivacyPolicyPage(this.selectedCampaignUrlId);
    } else {
      this.getPrivacyPolicyPagePreview(this.selectedCampaignId);
    }
    if (this.selectedCompanyId) {
      this.adminService.getCompanyDetails(this.selectedCompanyId);
    }
  }

  public getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public getPrivacyPolicyPage(campaignUrlId: any): void {
    this.campaignService
      .getPrivacyPolicyPage(campaignUrlId)
      .pipe(
        takeUntil(this.destroyed$),
        tap((responseData: PrivacyPolicyModel): void => {
          if (responseData !== null) {
            this.privacyPolicy = responseData;
          }
        })
      )
      .subscribe();
  }
  public getPrivacyPolicyPagePreview(campaignId: number): void {
    this.campaignService
      .getPrivacyPolicyPagePreview(campaignId)
      .pipe(
        takeUntil(this.destroyed$),
        tap((responseData: PrivacyPolicyModel): void => {
          if (responseData !== null) {
            this.privacyPolicy = responseData;
          }
        })
      )
      .subscribe();
  }
  public navigateToCampaignBuilder(): void {
    if (this.selectedCompanyId) {
      this.adminService.getCompanyDetails(this.selectedCompanyId);
      this.router
        .navigate([
          `admin/${this.selectedCompanyId}/create-campaign/quiz/${this.selectedCampaignId}`,
        ])
        .then();
    }
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
