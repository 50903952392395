<div class="footer-container">
  <div class="container footer-holder">
    <div class="footer-logo-holder">
      <img
        ngSrc="assets/talentmarket.logo.svg"
        alt="talentmarket."
        class="footer-logo"
        height="70"
        width="210" />
      <p>Novi posao. Nadohvat ruke. Prijava za minutu.</p>
    </div>
    <div class="contact-footer-holder">
      <div class="social-footer-holder">
        <i class="social-button facebook-button">
          <fa-icon
            [icon]="faFacebookF"
            (click)="navigateToFacebook()"></fa-icon>
        </i>
        <i class="social-button linkedin-button">
          <fa-icon
            [icon]="faLinkedinIn"
            (click)="navigateToLinkedIn()"></fa-icon>
        </i>
      </div>
      <div class="contact-holder">
        <p>
          <fa-icon [icon]="faPhone"></fa-icon> &nbsp;
          <strong>099 6989 811</strong>
        </p>
        <p>
          <fa-icon [icon]="faEnvelope"></fa-icon> &nbsp;
          <strong>info&#64;talentmarket.io</strong>
        </p>
      </div>
    </div>
    <div class="footer-navigation">
      <p (click)="navigateToTop()">Na početak</p>
      <p (click)="navigateToAboutUs()">O nama</p>
      <p (click)="navigateToContactUs()">Kontaktiraj nas</p>
    </div>
  </div>
</div>
